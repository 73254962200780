module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/src/i18n","languages":["en","zh","ja"],"defaultLanguage":"zh","redirect":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"HEM","short_name":"HEM","start_url":"/","lang":"en","background_color":"black","display":"minimal-ui","icon":"src/images/favicon.png","localize":[{"start_url":"/en/","lang":"en","name":"HEM","short_name":"HEM"},{"start_url":"/zh/","lang":"zh","name":"HEM","short_name":"HEM"},{"start_url":"/ja/","lang":"ja","name":"HEM","short_name":"HEM"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5c7020c92196ef7473b54ad0b07099b2"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
